import React from 'react';
import {
  MuiAutocomplete as Autocomplete,
  Box,
  TextField,
} from '@cherre-frontend/ui';
import { Control, Controller } from 'react-hook-form';
import { DataValidationForm } from '../dataValidationFormEngine';

interface DataValidationFieldsArrayProps {
  control: Control<DataValidationForm>;
  label: string;
  uiReadOnly?: boolean;
  options: string[];
  propertiesType: 'generalProperties' | 'specificProperties';
  index: number;
}

export const DataValidationFieldsArray = ({
  propertiesType,
  index,
  options,
  label,
  control,
}: DataValidationFieldsArrayProps) => {
  const name = `${propertiesType}.${index}.value` as const;

  return (
    <Controller
      render={({ field }) => (
        <>
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              display: 'block',
              marginBottom: '5px',
              color: 'black',
            }}
            component='label'
          >
            {label}
            <Box component='span' color='#E53935'>
              *
            </Box>
          </Box>
          <Autocomplete
            multiple
            value={Array.isArray(field.value) ? field.value : [field.value]}
            options={options}
            getOptionLabel={(option) => option}
            onChange={(e, data) => field.onChange(data.map((d) => d))}
            renderInput={(params) => (
              <TextField
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  '& .MuiChip-root': {
                    height: '24px',
                  },
                }}
                {...params}
                variant='outlined'
              />
            )}
          />
        </>
      )}
      name={name}
      control={control}
      rules={{ required: true }}
      defaultValue={[]}
    />
  );
};
