import React, { Suspense, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import {
  Box,
  CircularProgress,
  ControlledSelect,
  FormControl,
} from '@cherre-frontend/ui';
import {
  DataValidationForm,
  useDataValidationFormContext,
} from './dataValidationFormEngine';
import { ValidationContainerFieldsQuery } from '../../../../queries/validationContainerFieldsQuery';
import { validationContainerFieldsQuery } from '../../../../queries/__generated__/validationContainerFieldsQuery.graphql';
import { Control } from 'react-hook-form';
import { useDataValidationV2 } from '../../../../providers/DataValidationV2Provider';
import { DataValidationFieldsArray } from './DataValidationField/DataValidationFieldsArray';

interface FieldsSelectProps {
  control: Control<DataValidationForm>;
  queryReference: PreloadedQuery<validationContainerFieldsQuery>;
  index: number;
  propertiesType: 'generalProperties' | 'specificProperties';
  disabled?: boolean;
  parameterName: string;
}

const FieldsSelect = ({
  control,
  queryReference,
  index,
  propertiesType,
  disabled,
  parameterName,
}: FieldsSelectProps) => {
  const { validationContainerFields } = usePreloadedQuery(
    ValidationContainerFieldsQuery,
    queryReference
  );

  const options =
    validationContainerFields?.map((field) => ({
      label: field?.name || '',
      value: field?.name || '',
    })) || [];

  if (parameterName === 'fieldsArray') {
    return (
      <DataValidationFieldsArray
        control={control}
        label='Field'
        options={options.map((option) => option.label)}
        propertiesType={propertiesType}
        index={index}
        uiReadOnly={disabled}
      />
    );
  }

  return (
    <ControlledSelect
      control={control}
      name={`${propertiesType}.${index}.value`}
      options={options}
      label='Field'
      required
      placeholder='Field'
      inputProps={{ sx: { padding: '8px' } }}
      disabled={disabled}
    />
  );
};

interface DataValidationTableFieldsProps {
  index: number;
  propertiesType: 'generalProperties' | 'specificProperties';
  parameterName: string;
}

export const DataValidationTableFieldsContent = ({
  index,
  propertiesType,
  parameterName,
}: DataValidationTableFieldsProps) => {
  const { validationContainer } = useDataValidationV2();

  const { control, watch } = useDataValidationFormContext();

  const generalProperties = watch('generalProperties');

  const selectedTableRef = generalProperties?.find(
    (property) => property.parameterName === 'tableRef'
  )?.value;

  const selectedTableContainerId = validationContainer?.find(
    (container) => container?.tableName === selectedTableRef
  )?.containerId;

  const [queryReference, loadQuery] =
    useQueryLoader<validationContainerFieldsQuery>(
      ValidationContainerFieldsQuery
    );

  useEffect(() => {
    if (selectedTableContainerId) {
      loadQuery({ containerId: Number(selectedTableContainerId) });
    }
  }, [selectedTableContainerId, loadQuery]);

  return (
    <FormControl sx={{ width: '100%', flex: 1, position: 'relative' }}>
      {queryReference ? (
        <FieldsSelect
          control={control}
          queryReference={queryReference}
          index={index}
          propertiesType={propertiesType}
          disabled={!selectedTableRef}
          parameterName={parameterName}
        />
      ) : (
        <ControlledSelect
          control={control}
          name={`${propertiesType}.${index}.value`}
          options={[]}
          label='Field'
          required
          placeholder='Field'
          inputProps={{ sx: { padding: '8px' } }}
          disabled={!selectedTableRef}
        />
      )}
    </FormControl>
  );
};

export const DataValidationSuspensaFallback = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <CircularProgress size={22} />
    </Box>
  );
};

export const DataValidationTableFields = (
  props: DataValidationTableFieldsProps
) => {
  return (
    <Suspense fallback={<DataValidationSuspensaFallback />}>
      <DataValidationTableFieldsContent {...props} />
    </Suspense>
  );
};
